<template>
  <v-container>
    <v-row>
      <v-col cols="12">{{termin.fr}}</v-col>
      <v-col cols="6" style="display: flex; flex-wrap: wrap">
        <div
            style="border: 2px solid #d7d7d7; width: 120px; color: #b6b6b6; margin: 4px; padding: 10px; display:flex; align-items: center; justify-content: center;"
            :class="{'fr': t.status}"
            v-for="t in termin.days"
        >
          <span>{{ t.time }}</span>
        </div>
      </v-col>
      <v-col cols="12" >
        Test view
        {{ mon }}

        <!--        {{test('2021-06-01')}}-->

        <hr>
        <v-btn color="primary" class="mt-6" @click="test('2021-05-01')">Load</v-btn>
        <v-btn color="primary" class="mt-6" @click="ts1">ts1</v-btn>
        <v-btn color="primary" class="mt-6" @click="ts2">ts2</v-btn>
        <v-btn color="primary" class="mt-6" @click="ts3">ts3</v-btn>
        <v-btn color="primary" class="mt-6" @click="ts4">ts4</v-btn>
        <v-btn color="primary" class="mt-6" @click="mxCalendar">Calendar</v-btn>
        <v-btn color="primary" class="mt-6" @click="printTime">PrintTime</v-btn>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>

import moment from 'moment'

export default {
  data() {
    return {
      mon: {},
      nds: {
        '2021': [
          {
            '04': [
              {
                '02': {'dr_id': '1254', 'times': ['08:15', '10:00', '12:30', '12:45']},
                '03': {'dr_id': '1254', 'times': ['08:00', '08:30', '09:30', '16:45']},
                '12': {'dr_id': '1254', 'times': ['07:45', '08:15', '09:15', '10:45']},
              }
            ]
          }
        ]
      },
      days: {
        '2021': [
          {
            '04': [
              {'02': ['08:15', '10:00', '12:30', '12:45']},
              {'09': ['08:15', '09:00', '11:30', '14:00']},
              {'10': ['08:15', '08:30', '09:30', '09:45', '16:30']}
            ]
          },
          {
            '05': [
              {'05': ['08:15', '10:00', '12:30', '12:45']},
              {'07': ['08:15', '09:00', '11:30', '14:00']},
              {'17': null}
            ]
          },
          {
            '06': [
              {'02': ['08:15', '10:00', '12:30', '12:45']},
              {'13': ['08:15', '09:00', '11:30', '14:00']},
              {'27': null},
              {'28': null},
              {'29': null},
            ]
          }
        ]
      },
      calendar: {
        daysArray: []
      },
      termin: {
        step: 15,
        work_time: {
          start: '08:30',
          end: '16:30'
        },
        days: [],
        service_plus: true,
        fr: ['10:30', '11:45', '14:15']
      }
    }
  },
  created() {
    // this.test('2021-05-07')

  },
  computed: {
    // test() {
    //   let ob_days = Object.assign(this.days)
    //   for (let day in ob_days) {
    //     //console.log(Object.keys(ob_days[day][2]));
    //     this.getMon(ob_days[day], '2021-05-07')
    //   }
    // },
  },
  methods: {
    adZ(num) {
      return num < 10 ? '0' + num : num
    },

    printTime() {
      const work_time_start_h = parseInt(this.termin.work_time.start.split(':')[0])
      const work_time_start_m = parseInt(this.termin.work_time.start.split(':')[1])

      const work_time_end_h = parseInt(this.termin.work_time.end.split(':')[0])
      const work_time_end_m = parseInt(this.termin.work_time.end.split(':')[1])

      let hour = parseInt(work_time_start_h)
      let min  = parseInt(work_time_start_m)

      let result = []
      const step = this.termin.step

      for (let i = hour; i <= work_time_end_h; i++) {
        for (let x = min; x <= 60; x += step) {
          if (work_time_end_h === i && work_time_end_m === x) {
            min = 0
            break
          }
          if (x === 60) {
            min = 0
            break
          }
          let time = `${this.adZ(i)}:${this.adZ(x)}`
          let status = this.isTerminFr(time)

          result.push({time: time, status: status})
        }
      }

      this.termin.days = result

      if (this.termin.service_plus) {
        this.servicePlus(90)
      }

      return result
    },
    isTerminFr(time) {
      return this.termin.fr.find(fr => fr === time) === undefined
    },
    checkTime(time) {
      for (let i = this.termin.step; i <= 60; i += this.termin.step) {
        if (time <= i) {
          return i
        }
      }
    },
    servicePlus(time) {
      time = this.checkTime(time)
      this.termin.days = this.termin.days.map(t => {
        const end_time = moment(`2022-04-29 ${t.time}`).add(time, 'minutes').format('HH:mm')
        const ds = this.termin.days
        let st = t.status
        let bl = false

        for (let i = 0; i < ds.length; i++) {
          if (end_time === ds[i].time) {
            break
          }
          if (t.time === ds[i].time) {
            bl = true
          }
          if (bl) {
            if (ds[i].status === false) {
              st = false
            }
          }
        }

        t.status = st
        return t
      })
    },

    test(c_day) {
      let ob_days = Object.assign(this.days)
      const c_date = c_day.split('-')

      for (let day in ob_days) {
        if (Object.keys(this.mon)[0] !== c_date[1]) {
          this.mon = this.getMon(ob_days[day], c_date[1])
          console.log('Mon: ', this.mon);
        }

        let days = this.getDays(this.mon)
        let d = this.getDay(days, c_date[2])


        console.log('Days: ', days);
        console.log('Day: ', d);
      }
    },
    getMon(obj, date) {
      for (let i = 0; i < obj.length; i++) {
        if (date === Object.keys(obj[i])[0]) {
          return obj[i]
        }
      }

      return null
    },
    getDays(mon) {
      const days = Object.values(mon)
      let result = []
      for (let i = 0; i < days.length; i++) {
        result.push(days[i])
      }

      return result[0]
    },
    getDay(days, date) {
      for (let i = 0; i < days.length; i++) {
        if (date === Object.keys(days[i])[0]) {
          return days[i]
        }
      }
      return null
    },

    ts1() {
      let currentDate = moment();
      let weekStart = currentDate.clone().startOf('week');
      let weekEnd = currentDate.clone().endOf('week');

      let days = [];
      for (let i = 0; i <= 6; i++) {

        days.push(moment(weekStart).add(i, 'days'));

      }

      console.log(days);
      console.log(moment('01/19/2016').format("MMMM Do,dddd"));
    },
    ts2() {
      const weekStart = moment().startOf('week');

      const days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format("YYYY-MM-DD"));
      }

      console.log(days);
      return days;
    },
    ts3() {
      console.log(moment().subtract(7, 'days').calendar())
      console.log(moment().calendar(null, {
        sameDay: '[Сегодня]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'DD/MM/YYYY'
      }))
      console.log(moment().calendar('01.05.2021', "YYYY-MM-DD"));

      console.log(moment().add(1, 'day').format('DD.MM.YYYY'));
    },
    ts4() {

      const format = 'YYYY-MM-DD'

      // Локализация
      moment.updateLocale('de', {
        week: {dow: 1} // Начало недели с понедельника (По стандарту начинается неделя с воскресения)
      })

      let start_month = moment().startOf('month')
      let start_month_week = moment().startOf('month').startOf('week') // если неделя начинается с понедельника
      // let start_month_week = moment().startOf('month').startOf('week').add(1, 'day')  // если неделя начинается с воскрессения


      let end_month = moment().endOf('month')
      let end_month_week = moment().endOf('month').endOf('week') // если неделя начинается с понедельника
      // let end_month_week = moment().endOf('month').endOf('week').add(1, 'day') // если неделя начинается с воскрессения

      console.log('Начало месяца:',start_month.format(format));
      console.log('Начало месяца с понедльника:',start_month_week.format(format));

      console.log('==================');

      console.log('Конец месяца:',end_month.format(format));
      console.log('Конец месяца до воскресения:',end_month_week.format(format));


      moment().add(1, 'day') // Прибавить
      moment().subtract(1,'day') // Вычисть


      let r = [...Array(7)]
      r = r.map(() => 1)

      console.log(r);


    },

    mxCalendar() {
      moment.updateLocale('de', {
        week: {dow: 1} // Начало недели с понедельника (По стандарту начинается неделя с воскресения)
      })

      let start_day = moment().startOf('month').startOf('week')
      let end_day = moment().endOf('month').endOf('week')

      let calendar = []
      let day = start_day.clone()

      while(!day.isAfter(end_day)) {
        calendar.push(day.clone())
        day.add(1, 'day')
      }

      console.log(calendar);
    },

    calendarGrid() {
      const totalDays = 42
      const daysArray = [...Array(totalDays)]

      this.calendar.daysArray.push(daysArray)

    }
  }
}
</script>

<style lang="scss">
.fr {
  border-color: #3d7c3d !important;
  color: #3d7c3d !important;
}
</style>






















